header {
  padding-right: 1em;

  div.logo {
    width: min(22vw, 300px)
  }

  div.top {
    border-bottom: 1px solid #cdcdcd;
  }

  div.burger {
    display: none;
  }

  #menuToggle menu {
    border: none;
    position: static;
    width: auto;
    height: auto;
    background-color: white;
    transform: none;
    padding: 0;
    margin: 0;

    & > li {
      position: relative;
      display: inline-block;

      & > menu.sub {
        position: absolute;
        min-width: max-content;
        text-align: left;
        & > li {
          padding: 1em 1em;
        }
      }
      a {
        padding: 1em 0.35em;
      }
    }
  }
}


footer {
  justify-content: space-around;
  .container {
    flex-wrap: nowrap;
  }
}
