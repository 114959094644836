.breed-finder {
  margin: 2em auto;

  .breed-selector {
    img {
      margin-left: 0;
    }

    p {
      z-index: 1;
    }
  }
}

.search-bar {
  margin: 2em auto;
}

bn-webshop-pet-page {
  .product {
    bn-pet-images {

      .images {
        flex-direction: row;
        min-height: 20em;
      }

      .thumbnails {
        min-height: 20em;
        max-width: 82px;
        margin: 0;
        margin-right: 1em;
        .scroller {
          flex-direction: column;
          margin: 0;
        }
      }
    }
  }
}
