$header-padding-right: 3em;

header {
  max-width: 1440px;

  display: flex;
  justify-content: space-between;

  z-index: 100;

  margin: auto;

  padding-top: 1em;
  padding-right: $header-padding-right;

  div {
    &.logo {
      width: 200px;
      height: auto;
      aspect-ratio: 100 / 55;

      img {
        cursor: pointer;
        width: 100%;
        margin-bottom: -4em;
        margin-top: -2em;
        padding: 2em 1em 3em 2em;
      }
    }

    &.menu {
      display: flex;
      flex-direction: column;
      flex-grow: 1;

      div.top {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 0.3em;

        img {
          padding: 0 0.5em;
        }
      }
    }
  }

  #menuToggle {
    z-index: 100;
    display: block;
    position: relative;

    -webkit-user-select: none;
    user-select: none;

    a {
      color: #545454;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      line-height: normal;
      text-decoration: none;

      transition: color 0.25s ease;

      &:hover {
        color: $orange;
      }

      &.active {
        font-weight: 700;
      }
    }

    $burger-top: 10px;
    $burger-right: 0;

    div.burger {
      position: absolute;
      right: $burger-right;
      top: $burger-top;
    }

    input {
      position: absolute;

      margin: 0;
      display: block;
      width: 40px;
      height: 32px;

      right: $burger-right;
      top: $burger-top;

      cursor: pointer;

      opacity: 0; /* hide this */
      z-index: 102; /* and place it over the hamburger */

      -webkit-touch-callout: none;
    }

    span {
      position: relative;
      display: block;
      width: 40px;
      height: 6px;
      margin-bottom: 6px;

      background: #cdcdcd;
      border-radius: 3px;

      z-index: 101;

      transform-origin: 37px 3px;

      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
      opacity 0.55s ease;
    }

    input:checked ~ div.burger {
      span {
        opacity: 1;
        transform: rotate(-45deg);
        background: $orange;
      }

      span:nth-child(2) {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }

      span:nth-child(3) {
        transform: rotate(45deg);
      }
    }

    menu li {
      list-style-type: none;
    }

    & > menu {
      position: absolute;
      top: -4.6em;
      right: -4.3em;
      width: 100vw;
      height: 100vh;
      text-align: right;

      padding: 4em;
      padding-top: 8em;
      border-radius: 8px;

      background: white;

      list-style-type: none;
      -webkit-font-smoothing: antialiased;
      /* to stop flickering of text in safari */

      transform-origin: 0% 0%;
      transform: translate(calc(100% + $header-padding-right), 0);

      transition: transform .25s cubic-bezier(0.77, 0.2, 0.05, 1.0);

      & > li {
        padding: 10px 0;


        &:has(menu.sub):hover > a:after {
            transform: rotate(30deg);
            filter: brightness(0) saturate(100%) invert(88%) sepia(73%) saturate(479%) hue-rotate(313deg) brightness(95%) contrast(111%);
            transition: transform,filter 0.1s, 0.1s;
        }

        &:has(menu.sub) > a:after {
          background: url("/assets/decoration/nav-triangle.svg") no-repeat;
          background-size: contain;
          width: 14px;
          height: 14px;
          margin-left: 7px;
          margin-right: 10px;
          display: inline-block;
          content: "";
          transform: rotate(-90deg);
          transition: transform 0.5s;
        }


        & > menu.sub {
          text-align: right;

          top: 3em;
          margin: 0;
          max-height: 0;
          overflow: hidden;

          transition: max-height 0.5s;

          li {
            display: block;
            padding: 0.5em 1em;

            a {
              font-size: 1em;
            }
          }
        }

        &:hover > menu.sub,
        &.selected > menu.sub {
          max-height: 100vw;
          transition: max-height 0.5s;
        }
      }
    }

    input:checked ~ menu {
      transform: none;
      menu.sub {
        max-height: 100vw;
        transition: max-height 0.5s;
      }
    }
  }
}

.message {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-out;

  align-items: center;
  border: 10px dashed $orange;
  width: min(600px, 90%);
  border-radius: 5em;
  font-size: 1.2em;
  font-family: "Poppins", sans-serif;
  text-align: center;

  &.visible {
    max-height: 600px;
    margin: 2em auto;
    padding: 1em 2em;
    transition: max-height 0.5s ease-in, margin 0.5s ease-in, border-width 0.5s ease-in;
  }

  &.hidden {
    max-height: 0;
    border-width: 0;
    margin: 0 auto;
    padding: 0;
    transition: max-height 0.5s ease-out, margin 0.5s ease-out, border-width 0.5s ease-out;
  }

  &.info {
    border-color: $light-blue;
  }
  &.warning {
    border-color: $yellow-60;
  }
  &.error {
    border-color: $orange;
  }
}

footer {
  $line-size: 640px;
  $bottom-offset: 10px;
  $line-bottom-offset: 30px;
  $line-center-offset: 390px;

  background: url("/assets/decoration/footer_decorline.svg") no-repeat,
  url("/assets/decoration/footer_decorline.svg") no-repeat,
  url("/assets/logo/BN_wm_white.svg") no-repeat #90CBE5;

  background-size: 640px, 640px, 97px;

  background-position: // [vízszintes igazítás] [függőleges igazítás]
    calc(50% - $line-center-offset) calc(100% - $line-bottom-offset),
    calc(50% + $line-center-offset) calc(100% - $line-bottom-offset),
    center bottom $bottom-offset;

  margin: 2em auto auto;

  display: flex;
  align-items: center;

  .container {
    max-width: 1900px;
    padding: 2em;
    padding-top: 1em;
    padding-bottom: 6em;

    display: flex;
    align-items: center;

    flex-wrap: wrap;
    justify-content: center;


    &, a, p {
      color: #545454;
      text-decoration: none;
      font-family: Poppins, sans-serif;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }

    a:hover {
      color: $orange;
    }

    div {
      padding: 1em;
      width: 100%;
      text-align: center;
    }

    div.newsletter {
      input, .button {
        font-size: 15px;
      }

      p {
        font-weight: bold;
        font-size: 1.1em;
      }
    }
    div.legal {
      img {
        margin-top: 2em;
        max-width: 22em;
      }
    }
  }
}
