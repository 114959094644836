h1 {
  font-size: 30px;
}

h2 {
  background-size: contain;
  font-size: 19px;
}

article, p, .content {
  font-size: 20px;
  text-align: left;
}

@import "layout";
@import "heroes";
@import "webshop";
@import "product_scroller";
