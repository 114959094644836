/* You can add global styles to this file, and also import other style files */
@import "style/main";
@import "style/1_mobile/main";

$responsive-debug: false;

@if $responsive-debug {
  body {
    background-color: rgba(72, 255, 0, 0.35);
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  @if $responsive-debug {
    body {
      background-color: rgba(247, 255, 0, 0.24);
    }
  }
  @import "style/2_tablet/main";
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and ((min-width: 992px) or ((min-width: 768px) and (orientation: landscape))) {
  @if $responsive-debug {
    body {
      background-color: rgba(255, 128, 0, 0.2);
    }
  }
  @import "style/3_small_desktop/main";
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1400px) {
  @if $responsive-debug {
    body {
      background-color: rgba(255, 0, 0, 0.16);
    }
  }
  @import "style/4_desktop/main";
}
