.breed-finder {
  margin: 2em;

  .breed-selector {
    img {
      z-index: 0;
      display: block;
      margin-left: -10vw;
      width: 50%;
    }

    p {
      z-index: 1;
      width: 100%;
      text-align: center;
    }
  }
}

.search-bar {
  margin: 2em;
}

bn-webshop-pet-page {
  .product {
    flex-direction: row;
    // justify-content: space-evenly;
    gap: 2em;

    bn-pet-images {
      flex-shrink: 0;
      max-width: min(850px, 60%);

      .thumbnails {
        bn-spinloader {
          min-height: 20em;
        }
      }
    }

    bn-pet-details {
      flex-shrink: 1;
      min-width: 350px;
      max-width: 500px;

      &:first-child {
        display: none;
      }

      &:last-child {
        .details {
          .header {
            display: block;
          }
        }
      }
    }
  }
}


.not-found {
  margin: 3em auto;
}
