header {
  div.menu {
    flex-grow: 0;
  }
}

footer {
  justify-content: space-around;

  .container {
    div {
      width: auto;
      text-align: left;
    }
  }
}
