.breed-finder {
  flex-direction: row;

  article, div {
    margin: 0.5em;
  }
}

.search-bar {
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  justify-content: flex-end;

  .search-box {
    width: auto;
  }
}

bn-webshop-pet-page {
  .product.details {
    .tab-buttons {
      flex-direction: row;
      button {
        width: 10em;
      }
    }
  }
}

