$orange: #f98626;
$orange-80: rgb(250, 158, 81);
$orange-60: rgb(251, 183, 125);
$orange-40: rgb(253, 206, 168);
$orange-20: rgb(254, 231, 211);

$orange-alt-1: #F98524;
$orange-light: #fcd17f;

$orange: #f78424;

$yellow-20: #fff0d5;
$yellow-40: #fff0d5;
$yellow-60: #fed381;
$yellow-80: #fec557;
$yellow: #FEB62C;

$yellow-light: $yellow-60;

$dark-blue-20: #cdd6da;
$dark-blue-40: #9caeb6;
$dark-blue-60: #6a8491;
$dark-blue-80: #395b6c;
$dark-blue-90: #164357;
$dark-blue: #073347;

$light-blue-20: #e9f5fa;
$light-blue-40: #d3eaf5;
$light-blue-60: #bde0ef;
$light-blue-80: #a7d5ea;
$light-blue: #91cbe5;

$light-grey: #f1f4f4;
$light-grey-80: #f4f6f6;
$light-grey-60: #f7f8f8;
$light-grey-40: #f9fbfb;
$light-grey-20: #fcfdfd;

$dark-grey: #555555;
$dark-grey-60: #949699;
$dark-grey-20: #ddddde;


$main-fg: #1e1e1e;

@import "fonts";

html, body, main {
  margin: 0 auto;
  padding: 0;
  width: 100%;
  color: $main-fg;
  overflow-x: hidden;
  min-height: 80vh;
}

h1, h2, h3, h4, h5, h6 {
  color: $dark-blue-90;
  font-family: "Luckiest Guy", cursive;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  em {
    color: $yellow;
  }
}

input, input:focus {
  padding: 0.6em;
  margin: 0.5em;
  border: 1px solid #fff;
  font-size: 18px;
  border-radius: 10px;
  outline: none;
}

.button {
  padding: 0.6em;
  margin: 0.5em;
}


.button {
  display: flex;
  align-items: center;
  gap: 0.5em;

  padding: 10px 10px;
  border-radius: 8px;
  border: 3px solid $orange-alt-1;

  cursor: pointer;

  font: 20px "Poppins", sans-serif;
  font-style: normal;
  line-height: 100%;

  text-decoration: none;

  color: $orange-alt-1;

  &.primary {
    background-color: $orange-alt-1;
    color: #F5F5F5;
  }
}

p, article {
  color: #545454;
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;

  a:not(.button) {
    color: $orange;
    text-decoration: none;
    transition: color 0.5s;
    &:hover {
      text-decoration: underline;
      color: $yellow;
      transition: color 0.5s, text-decoration 0.5s;
    }
  }
}

.highlight {
  width: 100%;
  padding: 1em 0;

  text-align: center;

  background: transparent url(/assets/decoration/h2_bg.svg) no-repeat center center;
  color: #1E1E1EFF;

  font-family: "Luckiest Guy",cursive;
  font-size: 22px;
  font-weight: 400;
}

// cart anim
.basket {
  .loading {
    animation: spin 1s ease infinite;
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes border-glow {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: $light-blue;
  }
  100% {
    border-color: transparent;
  }
}

