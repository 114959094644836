.hero {
  &.main {
    & > div.image {
      flex-grow: 1;

      min-height: 40vw;

      img {
        &.main {
          top: -7%;
          left: 30%;
          width: 70%;
        }

        &.arrow, &.reno {
          z-index: 1;
        }

        &.reno {
          left: -5%;
          width: 50%;
          top: 20%
        }

        &.arrow {
          right: 55%;
          top: 55%;
          width: 20%;
        }
      }
    }
  }

  &.products {
    .scroller {
      .nav {
        margin: 0 1em;
        display: flex;
      }

      .item-scroller {
        width: 78vw;

        .item {
          zoom: 1;
          width: auto;
          min-width: 290px;
        }
      }
    }
  }

  &.motto {
    padding: 1em;

    background: transparent url("/assets/decoration/hero-yellow-bg.svg") no-repeat center center;
    background-size: cover;

    div.motto {
      padding: 2em 2em 0;

      h1 {
        margin: 1em;
      }
    }
  }

  &.reference {
    .nav {
      img {
        filter: none;
      }
      &.back {
        margin-right: 0;
        margin-left: 0;
      }
      &.forward {
        margin-left: 0;
        margin-right: 0;
      }
    }

    .item-scroller {
      .item {
        div.quote {
          padding: 3em;
        }

        .container {
          width: min(1260px, calc(100vw - 12em));
          padding: 9em 1px 0;

          img.qty {
            width: 260px;
            top: -53px;
            left: calc(50% - 130px);
          }
        }

        img.quotemark {
          display: block;
        }
      }
    }
  }

  &.community {
    min-height: 663px;
    .container {
      padding: 3em 1em 1em;
      form {
        flex-direction: row;
        justify-content: center;
      }
    }
  }


  &.contact {
    flex-wrap: nowrap;
    flex-direction: row;
    & > div {
      width: 40%;
    }
    & > bn-contact-form {
      width: 60%;
    }
  }

  &.why-personalize {
    padding-top: revert;
  }


  &.how-does-it-work {
    .content {
      article {
        max-width: 40%;
        padding: 0;
      }
    }
  }

  &.image-tips {
    .content {
      flex-direction: row;
    }
    img {
      max-width: 50%;
    }
  }

}

.panel-row {
  flex-wrap: nowrap;
}


.curved-bg {
  &.yellow {
    background: url(/assets/decoration/hero-yellow-bg-mid.svg) no-repeat center top;
  }

  &.orange {
    background: url(/assets/decoration/hero-orange-bg-mid.svg) no-repeat center top;
  }
}
