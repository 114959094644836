.scroller {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  overflow-x: hidden;

  .nav {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    cursor: pointer;

    &.back {
      margin-right: -3em;
    }
    &.forward {
      transform: rotate(180deg);
      margin-left: -3em;
    }
  }

  .item-scroller {
    margin: 1em 0;
    padding: 1em 3em;
    display: flex;
    gap: 2em;
    flex-direction: row;
    flex-flow: nowrap;
    align-items: normal;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;

    .item {
      scroll-snap-align: center;
      box-sizing: border-box;
      flex-shrink: 0;
    }
  }
}
