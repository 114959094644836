.hero {
  max-width: 1440px;
  margin: auto;

  &.decor-line-bg {
    background-size: contain;
  }

  &.main {
    max-width: 1440px;

    min-height: 610px;

    & > article {
      padding: 70px;
      width: 470px;
    }

    & > div.image {
      height: 660px;
      min-height: auto;

      img {
        &.main {
          width: 130%;
          left: -5%;
          top: -51%;
        }
        &.reno {
          top: 0;
        }
        &.arrow {
          top: 40%
        }
      }
    }
  }

  &.knowus {
    div.content {
      div.image {
        img {
          width: 640px;
        }
      }

      article {
        padding: 105px;
      }

    }
  }
  &.products {
    p {
      max-width: 948px;
    }

    .scroller .item-scroller {
      width: 1250px;
    }
  }


  &.contact {
    & > * {
      width: 45%;
    }
  }

  &.about-us {
    article {
      padding: 7em;
    }
  }

  &.how-does-it-work {
    .content, .pet-container {
      max-width: 50%;
    }
    .content {
      article {
      }
    }
  }

}

