h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

.highlight {
  background-size: contain;
  font-size: 19px;
}

hr {
  border-style: solid;
  border-width: 1px;
  color: $orange-20;
}

article, p, .content {
  text-align: center;
  color: #545454;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

bn-newsletter-subscribe-form {
  form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;

    margin: auto;
    text-align: center;

    input, input:focus {
      padding: 0.6em;
      margin: 0.5em;
      border: 1px solid #fff;
      font-size: 18px;
      border-radius: 10px;
      outline: none;
    }

    .button {
      padding: 0.6em;
      margin: 0.5em;
    }
  }
  h3 {
    text-align: center;

    &.success {
      color: $dark-blue;
    }

    &.error {
      color: red;
    }
  }
}



@import "layout";
@import "heroes";
@import "product_scroller";
@import "webshop";
