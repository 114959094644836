header {
  padding-right: 6em;

  #menuToggle menu li a {
    padding: 1em;
  }

  div.logo img {
    padding: 2em 1em 3em 2em;
  }
}


footer {
  padding: 2em 4em 6em;
}


