@font-face {
  font-family: 'Luckiest Guy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/font/LuckiestGuy.ttf);
}

/*
  Poppins italic
 */
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(/assets/font/Poppins-ThinItalic.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url(/assets/font/Poppins-ExtraLightItalic.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/font/Poppins-LightItalic.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/font/Poppins-LightItalic.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/font/Poppins-Italic.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(/assets/font/Poppins-Italic.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/font/Poppins-Italic.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/font/Poppins-SemiBoldItalic.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/font/Poppins-BoldItalic.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url(/assets/font/Poppins-ExtraBoldItalic.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url(/assets/font/Poppins-ExtraBoldItalic.ttf);
}

/*
  Poppins normal
 */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(/assets/font/Poppins-Thin.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(/assets/font/Poppins-ExtraLight.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/font/Poppins-Light.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/assets/font/Poppins-Light.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/assets/font/Poppins-Regular.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/assets/font/Poppins-Regular.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/font/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/assets/font/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/assets/font/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/assets/font/Poppins-ExtraBold.ttf);
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/assets/font/Poppins-ExtraBold.ttf);
}
