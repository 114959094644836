.hero {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;

  article {
    display: flex;
    flex-direction: column;
    padding: 1em;
  }

  img.quotemark {
    width: 84px;

    &.start {
      float: left;
      margin-top: -25px;
    }
  }

  ul.checks {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      color: #545454;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;

      display: flex;


      img, span {
        padding: 0.5em;
        display: flex;
        align-items: center;
      }

      span {
        flex-grow: 1;
        text-align: left;
      }
    }
  }


  &.main {
    & > article {
      align-items: center;
      background-color: white;
      z-index: 2;

      &, p {
        font-size: 23px;
      }

      h1 {
        text-transform: uppercase;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
      }
    }

    & > div.image {
      overflow: hidden;
      height: 60vw;

      position: relative;

      img {
        position: absolute;

        &.main {
          top: -7%;
          left: 30%;
          width: 70%;
        }

        &.arrow, &.reno {
          z-index: 1;
        }

        &.reno {
          left: -5%;
          width: 50%;
          top: 20%
        }

        &.arrow {
          right: 55%;
          top: 55%;
          width: 20%;
        }
      }
    }
  }

  &.knowus {
    div.content {
      display: flex;
      flex-direction: column;
      align-items: center;

      div.image {
        img {
          width: 95%;
        }
      }

      article {
        align-items: center;
        justify-content: space-evenly;

        p {
          text-align: left;
        }
      }
    }
  }

  &.products {
    flex-direction: column;
    align-items: center;

    p {
      margin: 1em auto;
      max-width: 948px;
      text-align: center;
    }

    bn-product-scroller {
      width: 100%;
    }

    .scroller {
      background: transparent url("/assets/decoration/home_designline.svg") no-repeat center center;
      justify-content: normal;

      .item-scroller {
        gap: 1.5em;
        scroll-behavior: unset;

        .item {
          zoom: 0.7;
          &:first-child {
            margin-left: 2em;
          }

          &:last-child {
            margin-right: 2em;
          }

          border-radius: 15px;
          border: 3px solid $orange-light;
          background-color: white;
        }
      }
    }

    .no-bg .scroller {
      background: transparent;
    }
  }

  &.motto {
    background: transparent url("/assets/decoration/hero-yellow-bg-small.svg") no-repeat center center;
    background-size: cover;

    display: flex;
    flex-direction: column;
    align-items: center;


    & > div {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &.image {
        align-items: flex-start;
      }
    }

    div.motto {
      h1 {
        margin: 2em 2em 0;
        color: #FFF;
        text-align: center;
        font-family: "Luckiest Guy";
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
      }
    }
  }


  &.whyus {
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;
    //padding: 1em;

    div.image {
      img {
        width: 100%;
      }
    }

    article {
      text-align: left;
    }
  }

  &.reference {
    p {
      text-align: center;
      margin: 0 auto;
    }

    .nav {
      z-index: 10;
      position: relative;

      img {
        padding: 1em;
        filter: drop-shadow(0 0 0.5em $dark-blue);

        &:hover {
          filter: drop-shadow(0 0 0.5em $orange);
        }
      }

      &.back {
        margin-left: -1em;
        margin-right: -2em;
      }

      &.forward {
        margin-left: -2em;
        margin-right: -1em;
      }
    }

    .item-scroller {
      width: 100%;

      display: flex;
      align-items: center;
      gap: 2em;

      .item {
        &:first-child {
          margin-left: 2em;
        }

        &:last-child {
          margin-right: 2em;
        }

        .container {
          width: calc(100vw - 4em);
          padding: 1em 1px 0;
          margin: 5em 0;

          position: relative;

          img.qty {
            width: 200px;
            position: absolute;
            top: -135px;
            left: calc(50% - 100px);
          }
        }

        img.quotemark {
          display: none;
        }

        div.close-decor {
          min-height: 191px;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: -105px;
        }

        div.quote {
          border-radius: 50px;
          background-color: #FBCCA6FF;
          // alsó padding kb a decor magasságának fele
          padding: 3em 3em 96px;

          p {
            text-align: left;
            color: #545454;
            &.customer {
              margin-top: 1em;
            }
          }
        }
      }
    }
  }

  &.community {
    width: 100%;

    text-align: center;
    background: url(/assets/decoration/hero-blue-bg.svg) no-repeat center top,
    linear-gradient(180deg, rgba(0, 255, 255, 0) 0%, #A5D3E8FF 50%, #A5D3E8FF 100%);

    .container {
      flex-grow: 1;
      background: url(/assets/img/kozosseg_kutyak.png) no-repeat center bottom;
      background-size: contain;
      min-height: 615px;
      padding: 3em 1em 23vw;

      div.decor {
        display: none;
      }

      p {
        max-width: 750px;
        margin: 1em auto;
      }
    }

    bn-newsletter-subscribe-form {
      width: 80%;
    }

    &.orange {
      background: url(/assets/decoration/hero-orange-bg.svg) no-repeat center top,
      linear-gradient(180deg, rgba(0, 255, 255, 0) 0%, #fbcca6 50%, #fbcca6 100%);
    }
  }

  &.contact {
    background: url(/assets/decoration/contact_designline.svg) no-repeat center center;
    flex-direction: column;

    div.cta-decor {
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      p {
        margin: auto;
        padding: 1em;
      }

      img {
        width: 85%;
        margin: auto;
      }
    }

    bn-contact-form {
      overflow: hidden;
      position: relative;

      .decor {
        z-index: 1;
        // height: 225px;
        height: min(20vw, 225px);
        position: absolute;

        &.left {
          transform: rotate(-51deg);
          bottom: -2%;
          left: -2%;
        }

        &.right {
          transform: rotate(136deg);
          top: -2%;
          right: -2%;
        }
      }

      form {
        margin: min(10vw, 6em);
        font-family: "Poppins", sans-serif;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;

        min-width: 220px;

        z-index: 0;

        background-color: white;

        padding: 1.5em;
        border-radius: 1em;

        border: min(1.2vw, 8px) dashed #FEB62C;

        label {
          width: 100%;
          margin: 0.5em 0;
          text-align: left;
        }

        input, textarea, button {
          display: block;
          width: 100%;
          padding: 12px;
          margin: 0.5em 0;

          box-sizing: border-box;

          border-radius: 8px;
          border: 1px solid #D7D7D7FF;
        }

        button {
          display: flex;
          flex-direction: row;
          align-content: center;
          justify-content: center;
          border: 1px solid #2C2C2CFF;

          bn-spinloader {
            max-width: 3em;
          }
        }
      }

      p {
        text-align: center;

        &.success {
          color: $dark-blue;
        }

        &.error {
          color: red;
        }
      }
    }
  }

  &.about-us {
    div.content {
      display: flex;
      flex-direction: column;

      margin-top: 2em;

      div.video {
        margin: auto;
        width: 100%;

        iframe {
          width: 80vw;
          height: calc(80vw / 1.7777);
        }
      }
    }
  }

  &.first-noodle {
    margin: 4em 0;
    position: relative;
    width: 100%;
    max-width: 100%;
    background: #FDCEA7;


    article {
      font-size: 18px;
      max-width: 986px;
      margin: auto;
      padding: 3em;
    }

    img.quotemark {
      width: 148px;
      margin-top: -38px;
      margin-left: 3em;
      position: absolute;

      &.end {
        right: 0;
        bottom: 0;
        margin-bottom: -32px;
        margin-right: 3em;
      }
    }
  }

  &.the-team {
    div.team {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;

      div.member {
        flex: 1 1 250px;
        max-width: 250px;
        padding: 1em 4em;

        p {
          font-size: 18px;
        }

        h3 {
          color: #053146;
          font-family: "Luckiest Guy";
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        img {
          width: 250px;
        }
      }
    }
  }

  &.mission {
    background-color: $orange-light;
    min-width: 100%;

    div.content {
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      max-width: 1440px;

      article, img {
        flex: 4 5 35%;
      }

      article {
        max-width: 1440px;
        padding: 1em 3em;
      }

      img {
        width: min(100%, 724px);
        max-width: 724px;
      }
    }
  }

  &.authenticity {
    div.form {
      padding: 2em;
    }

    article p {
      text-align: left;
    }

    form {
      text-align: left;
      display: flex;
      align-items: center;

      input {
        border-radius: 15px;
        background-color: white;
      }

      input[type=text], input[type=text]:focus {
        border: 2px solid gray;
        padding: 10px;
        background-color: #F4F4F4;
      }
    }

    img {
      max-width: 45%;
      height: auto;
    }
  }

  &.auth-elements {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: auto;
    padding: 2em;

    & > * {
      flex: 1 1 45%;
      padding: 2em;
    }

    ul {
      text-align: left;
    }
  }

  &.image-with-content {
    .content {
      article {
        padding: 2em;
      }

      img {
        max-width: 70%;
      }
    }
  }

  &.workshop-info {
    margin: 1em auto;

    .content {
      padding: 2em 0;
    }

    article {
      padding: 0 2em;
    }
  }

  &.workshop-content {
    .panel-row {
      align-items: flex-start;
    }

    article {
      p {
        font-size: 18px;
        text-align: left;
      }

      h1 {
        text-align: left;
        font-size: 22px;
      }
    }
  }

  &.workshop-bullets {
    img {
      max-width: 45%;
    }
  }

  &.why-personalize {
    padding-top: 2em;

    article, ul {
      padding: 2em;
    }
  }

  &.horizontal-bullet-points {
    .panel-row {
      align-items: flex-start;
      flex-wrap: wrap;

      article {
        p {
          font-size: 18px;
          text-align: left;
        }

        h1 {
          text-align: left;
          font-size: 22px;
        }
      }
    }
  }

  &.contact {
    a {
      text-decoration: none;
      color: inherit;
    }
  }


  &.how-does-it-work {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pet-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      article {
        padding: 0.5em 2em;
      }
    }
  }

  &.image-tips {
    .content {
      flex-direction: column-reverse;
    }

    img {
      max-width: 80%;
    }
  }

  &.takes-time {
    margin: 2em auto;
    padding: 2em;
  }

  &.workshop-personalize {
    margin-bottom: 3em;

    align-items: center;
    justify-content: center;
    p {
      text-align: center;
    }
  }

}

// .hero

main:has(bn-community-page) + footer,
main:has(bn-contact-page) + footer {
  margin-top: 0;
}

.panel-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  & > * {
    flex: 1 1 50%;
  }
}

.curved-bg {
  &.yellow {
    background: url(/assets/decoration/hero-yellow-bg-small.svg) no-repeat center top;
    background-size: cover;
  }

  &.orange {
    background: url(/assets/decoration/hero-orange-bg-small.svg) no-repeat center top;
    background-size: cover;
  }
}
