@mixin discount-price {
  color: $orange-80;
  font-weight: 600;
}

@mixin original-price {
  font-weight: normal;
  text-decoration: line-through;
  font-size: 0.9em;
}

.breed-finder {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;
  flex-wrap: wrap;

  margin: 1em;
  padding: 1em;

  border-radius: 25px;
  background: rgba(253, 206, 167, 0.50) url("/assets/decoration/paws.svg") no-repeat right center;

  article, div, img {
    flex-basis: 0;
    flex-grow: 1;
  }

  article, div {
    // padding: 1em;
  }

  img {
    flex-shrink: 2;
  }

  .breed-selector {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    justify-items: center;

    img {
      display: none;
      width: 25%;
      margin: -10em 0;
    }

    select {
      border-radius: 8px;
      border: 1px solid #d7d7d7;
      padding: 0.5em;
      margin: 0.5em;
      min-width: 17em;
      outline: $yellow;

      &.active {
        background: $yellow-20;
        border-color: $yellow;
      }
    }
  }
}

.search-bar {
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 1em;
  justify-content: end;
  max-width: 1240px;
  margin: 2em;

  .search-box {
    border-radius: 25px;
    border: 1px solid #545454;
    background: #F5F5F5;

    display: flex;
    align-items: center;
    padding: 0 1em;
    width: 100%;

    input {
      border: none;
      margin: 0.2em;
      width: 100%;
      background: transparent;
    }

    &:has(input:not(:placeholder-shown)) {
      background: $yellow-20;
      border-color: $yellow;
    }
  }

  .search-state {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    h2 {
      margin: 0;
    }

    button.cancel {
      border-radius: 2em;
      margin: 0 1em;
    }
  }
}


.badge {
  display: flex;
  align-items: center;

  font-family: "Poppins", sans-serif;
  padding: 0.275em 2em 0.275em 1em;
  border-radius: 15px;
  position: absolute;
  top: 0.5em;
  left: -0.7em;
  z-index: 2;
  font-size: 1.5em;

  &::before {
    display: inline-block;
    content: '';
    background: url("/assets/decoration/paw.svg") no-repeat center center;
    margin-right: 1em;
    width: 1.5em;
    height: 1.5em;
  }

  &.discount {
    background-color: $orange-80;
    color: white;
  }
}


$orange-light: #fcd17f;
.pets {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 290px));
  gap: 1.5em;
  justify-content: center;

  max-width: 1440px;
  margin: 2em auto;
}

/**
  * Pet card
  * A főoldalon és a terméklistában megjelenő kártyák stílusa
 */
.pet {
  min-height: 400px;
  --img-scale: 1;
  border-radius: 15px;
  border: 3px solid $orange-light;
  position: relative;

  background-color: white;

  &.clickable {
    cursor: pointer;
  }

  &:has(:hover, :focus) {
    --img-scale: 1.1;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 1.5em;
    padding-bottom: 1em;
    box-sizing: border-box;

    .img-container {
      width: 240px;
      height: 240px;
      overflow: hidden;
      border-radius: 5px;
    }

    img {
      transform-origin: center;
      transform: scale(var(--img-scale));
      transition: transform 0.4s ease-in-out;
      border-radius: 5px;
    }


    h3, p {
      margin: 0.6em;
      color: #545454;
      font-family: Poppins, sans-serif;
      font-size: 20px;
      font-style: normal;
      line-height: 140%;
    }

    h3 {
      font-weight: 600;
    }

    h3.discount {
      margin-bottom: 0;
    }

    p.price {
      &.previous {
        @include original-price;
        margin: 0 auto;
      }

      &.discount {
        margin: 0 auto;
        @include discount-price;
      }
    }

    .button {
      border-radius: 15px;
      color: #FFF;
      font-family: Poppins, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      padding: 0.5em;
      margin-bottom: 0;
    }
  }
}

bn-webshop-pet-page {
  h1 {
    margin-top: 0;
  }

  .product {
    max-width: 1440px;
    margin: 2em auto;
    padding: 0 1em;
    display: flex;
    flex-direction: column;
    /**
    FIXME: ennek centernek kéne lennie, de amikor az új képet betölti nagyon hülyén néz ki, hogy ugrál az egész doboz,
     animálni kellene a magasság-változtatást a laoding után
     */
    align-items: flex-start;

    bn-pet-images {
      width: 100%;

      img {
        border-radius: 15px;
      }

      .loading {
        animation: 1s infinite border-glow;
      }


      .images {
        bn-spinloader {
          min-height: 20em;
        }
      }

      .images {
        display: flex;
        flex-direction: column-reverse;
      }

      .thumbnails {
        .scroller {
          overflow-x: scroll;
          scrollbar-width: none;
          width: 100%;
          margin: 0.5em 0;
          display: flex;
          gap: 2px;
          height: 100%;
          flex-direction: row;
          justify-content: flex-start;

          img {
            border: 3px solid transparent;
          }

          img:hover {
            cursor: pointer;
            border: 3px solid $orange;
          }

          img.selected {
            border: 3px solid $orange-light;
          }
        }
      }

      div.full-images {
        // badge miatt
        position: relative;

        .badge {
          position: absolute;
          left: unset;
          right: -0.7em;
        }

        width: 100%;
        min-height: 10em;

        .scroller {
          border-radius: 2em;
          border: 3px solid transparent;
        }

        img {
          width: 100%;
        }
      }
    }

    bn-pet-details {
      width: 100%;

      p.price {
        text-align: left;
        font-size: 32px;
        font-family: "Poppins", sans-serif;
        line-height: 30px;
        &.discount {
          display: flex;
          gap: 0.4em;
          @include discount-price;
          span.previous {
            @include original-price;
            color: $dark-blue;
            font-size: 0.7em;
          }
        }
      }

      div.cart.widgets {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          text-align: left;
        }

        &.material {
          flex-direction: column;
          align-items: flex-start;

          p {
            font-size: 1.1em;
            display: block;
            margin: 0.3em 0;

            em {
              font-weight: bold;
              font-style: normal;
            }
          }

          div.material-selector {
            display: flex;
            flex-direction: row;
            gap: 1em;

            button {
              cursor: pointer;
              font-size: 1.1em;
              border: 1px solid $dark-grey;
              color: $dark-grey;
              padding: 0.5em 1em;
              text-transform: capitalize;

              &.active {
                color: $orange;
                border-color: $orange;
              }
            }
          }
        }

        .spinner {
          display: flex;
          flex-direction: row;

          border: 1px solid $orange-light;
          border-radius: 15px;
          padding: 3px;

          .spinner-buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-left: 1px solid $orange-light;

            button {
              background: transparent;
              border: none;
            }
          }

          input {
            text-align: center;
            max-width: 3em;
            padding: 0;
            margin: 0 1em;
            border-radius: 0;
          }
        }
      }

      .details {
        &:not(.mobile) {
          .header {
            display: none;
          }
        }

        &.mobile {
          p {
            margin: 0.5em 0;
          }
        }
      }
    }
  }

  .product.details {
    display: flex;
    flex-direction: column;
    gap: 0;

    .tab-buttons {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;

      button {
        border: 1px solid #FDCEA780;
        background-color: transparent;

        cursor: pointer;

        width: 100%;
        height: 3em;

        font-family: Luckiest Guy, cursive;
        font-size: 20px;
        font-weight: 400;
        line-height: 35px;
        color: #053146;
        text-align: center;

        transition: color 0.25s ease;

        &.selected {
          background: #EEEDED;
          border-color: #EEEDED;
        }

        &:hover {
          color: $orange;
        }
      }
    }

    .tab-content {
      min-height: 5em;
      background: #EEEDED;
      text-align: left;

      & > div {
        padding: 2em;
        min-height: 5em;
      }

      .hidden {
        display: none;
      }
    }
  }

  div.tab-content {
    width: 100%;
  }
}

.breadcrumb {
  max-width: 1440px;
  margin: 2em auto 1em;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    a, li {
      color: #545454;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-style: normal;
      line-height: normal;
      text-decoration: none;
      transition: color 0.25s ease;
    }

    a:hover {
      color: $orange;
    }
  }

  li:not(:last-child)::after {
    display: inline-block;
    margin: 0 .25rem;
    content: ">";
  }
}

.not-found {
  margin: 3em 1em;
  max-width: 1280px;
  text-align: center;
  background-color: $light-grey;
  padding: 2em;
  border-radius: 2em;

  a {
    color: $dark-blue;
  }
}

.details {
  bn-spinloader {
    min-height: 30em;
  }
}

.short-description {
  p {
    margin: 0.8em 0;
    text-align: left;
  }
}

