.hero {
  flex-direction: row;

  &.decor-line-bg {
    background: center bottom url(/assets/decoration/home_designline.svg) no-repeat;
    max-width: 100%;

    .content {
      max-width: 1440px;
      margin: auto;
    }
    &.dlbg-top {
      background-position: center top;
    }
  }

  &.decor-line-bg-2 {
    background: center bottom url(/assets/decoration/pages_designline.svg) no-repeat;
    max-width: 100%;

    .content {
      max-width: 1440px;
      margin: auto;
    }
    &.dlbg-top {
      background-position: center top;
    }
  }

  &.main {
    align-items: center;
    background-size: contain;
    align-content: stretch;

    & > article {
      width: 35vw;
    }

    & > div.image {
      flex-grow: 1;

      img {
        &.main {
          width: 113%;
          left: 0;
          top: -5%;
        }

        &.reno {
          top: 25%;
        }

        &.arrow {
          top: 50%
        }
      }
    }
  }

  &.knowus {
    div.content {
      flex-direction: row;

      div.image {
        width: 80%;
        flex-shrink: 1;
      }

      article {
        flex-shrink: 2;
        align-items: flex-start;
      }
    }
  }

  &.products {
    .scroller .item-scroller {
      width: 80vw;
    }
  }

  &.motto {
    flex-direction: row;
    min-height: 480px;
    justify-content: center;
    background-size: cover;

    & > div {
      width: 45%;
    }

    div.motto {
      h1 {
        color: #FFF;
        text-align: center;
        font-family: "Luckiest Guy";
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        line-height: 70px;
      }
    }
  }

  &.whyus {
    flex-direction: row;

    & > div, & > article {
      width: 48%;
    }
  }

  &.community {
    .container div.decor {
      display: block;
      /**
      Ne vedd ki a background-ot, CORS workaround localhoston
       */
      background: white url("/assets/decoration/decor_lines.svg") no-repeat;
      mask: url("/assets/decoration/decor_lines.svg") no-repeat center center;
      mask-size: cover;

      width: 210px;
      height: 210px;

      &.left {
        float: left;
        transform: scale(-1, 1) rotate(10deg);
      }

      &.right {
        float: right;
        transform: rotate(10deg);
      }
    }

    &.orange .container div.decor {
      background: url("/assets/decoration/decor_lines.svg") no-repeat;
      background-size: cover;
      mask: none;
    }
  }

  &.contact {
    flex-direction: row;

    & > div {
      width: 45%;
    }

    & > bn-contact-form {
      width: 55%;

      .decor {
        &.left {
          bottom: -5%;
          left: -4%;
        }

        &.right {
          top: -5%;
          right: -4%;
        }
      }
    }
  }

  &.about-us {
    padding: 1em 0 5em;
    width: 100%;

    div.content {
      flex-direction: row-reverse;
      flex-wrap: nowrap;
      margin-top: 0;

      & > article, & > div {
        flex-basis: 0;
      }

      article {
        flex-grow: 3;
        text-align: left;
        padding: 4em;
      }

      div.video {
        flex-grow: 4;
        margin: auto;

        iframe {
          // width="640" height="360"
          width: min(750px, 100%);
          height: auto;
          aspect-ratio: 16 / 9;
        }
      }
    }
  }

  &.mission {
    div.content {
      flex-direction: row;
      flex-wrap: wrap;
    }
  }

  &.auth-elements {

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin: 2em auto;

    & > * {
      flex: 1 1 45%;
      padding: 2em;
    }

    ul {
      text-align: left;
    }
  }

  &.image-with-content {
    .content {
      img {
        max-width: 45%;
      }
    }
  }

  &.workshop-content {
    padding: 5em 0;
    .panel-row {
      flex-wrap: nowrap;
    }
  }

  &.horizontal-bullet-points {
    .panel-row {
      flex-wrap: nowrap;
    }
  }


  &.how-does-it-work {
    flex-direction: row;
    justify-content: center;

    .pet-container {
      max-width: 35%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .content {
      max-width: 65%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

}

.panel-row {
  flex-wrap: nowrap;
}

.curved-bg {
  background-size: cover;
  height: auto;
  aspect-ratio: 1440 / 476;

  &.yellow {
    background: transparent url("/assets/decoration/hero-yellow-bg.svg") no-repeat center center;
  }

  &.orange {
    background: transparent url("/assets/decoration/hero-orange-bg.svg") no-repeat center center;
  }
}

